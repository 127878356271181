import React from "react";

function ButtonComponent(props) {
  return (
    <div
      style={{
        backgroundColor: "#693808",
        paddingTop: "10px",
        paddingBottom: "10px",
        paddingLeft: "20px",
        paddingRight: "20px",
        borderRadius: "30px",
        color: "white",
        width: "fit-content",
        height: "fit-content",
        display: "flex",
        flexDirection: "row",
        gap: "10px",
        alignItems: "center",
      }}
    >
      {props.text}
      {props.icon}
    </div>
  );
}

export default ButtonComponent;
