import React from "react";
import NavBarComponent from "../components/NavBarComponent";
import "./style.css";

function PrivacyPage() {
  return (
    <div>
      <NavBarComponent></NavBarComponent>

      <div
        className="section"
        style={{ display: "flex", flexDirection: "row" }}
      >
        <div>
          <div>
            <div className={"privacy-title"}>1. Information Collection:</div>
            <div className={"privacy-content"}>
              We want to assure you that we do not collect any personal
              information from users of the BSCUT.xyz Chrome extension or
              website. We have designed our services to operate without
              requiring the collection or storage of any personally identifiable
              information.
            </div>
          </div>
          <div>
            <div className={"privacy-title"}>
              2. Usage of Cookies and Tracking Technologies:
            </div>
            <div className={"privacy-sub-title"}>Cookies</div>
            <div className={"privacy-content"}>
              BSCUT.xyz does not use cookies or any similar tracking
              technologies that store or retrieve information on your computer
              or device.
            </div>
            <div className={"privacy-sub-title"}>Non-Personal Information</div>
            <div className={"privacy-content"}>
              We may collect non-personal information, such as your browser
              type, language preference, IP address, device type, and other
              technical information. However, this information is collected in
              an anonymous and aggregated form and cannot be used to identify
              you personally.
            </div>
          </div>
          <div>
            <div className={"privacy-title"}>3. Information Sharing:</div>
            <div className={"privacy-content"}>
              Since we do not collect any personal information, we do not share
              any information with third parties.
            </div>
          </div>
          <div>
            <div className={"privacy-title"}>4. Data Security:</div>
            <div className={"privacy-content"}>
              We take data security seriously and have implemented appropriate
              measures to protect the limited non-personal information we
              collect. These measures are designed to prevent unauthorized
              access, disclosure, alteration, or destruction of the data.
            </div>
          </div>
          <div>
            <div className={"privacy-title"}>5. Children's Privacy:</div>
            <div className={"privacy-content"}>
              BSCUT.xyz is not directed towards individuals under the age of 13.
              We do not knowingly collect any personal information from
              children. If we become aware that we have inadvertently collected
              personal information from a child, we will take steps to promptly
              delete it.
            </div>
          </div>
          <div>
            <div className={"privacy-title"}>
              6. Changes to this Privacy Policy:
            </div>
            <div className={"privacy-content"}>
              We may update this Privacy Policy from time to time to reflect
              changes in our practices or legal requirements. We encourage you
              to review this policy periodically for any updates. Your continued
              use of BSCUT.xyz following the posting of changes signifies your
              acceptance of those changes.
            </div>
          </div>
          <div>
            <div className={"privacy-title"}>7. Contact Us:</div>
            <div className={"privacy-content"}>
              If you have any questions, concerns, or requests regarding this
              Privacy Policy, please contact us at bscut@bscut.xyz.
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default PrivacyPage;
