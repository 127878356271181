import React from "react";
import ButtonComponent from "./ButtonComponent";

function NavBarComponent() {
  return (
    <div style={{ marginTop: "10px" }}>
      <nav class="navbar" role="navigation" aria-label="main navigation">
        <div id="navbarBasicExample" class="navbar-menu">
          <div class="navbar-start">
            <a class="navbar-item">BSCUT</a>
          </div>
          <div class="navbar">
            <a class="navbar-item">Pricing</a>
            <a class="navbar-item">Features</a>
            <a class="navbar-item">How to Use</a>
            <a class="navbar-item">FAQ</a>
          </div>
          <div class="navbar-end" style={{ marginRight: "10px" }}>
            <ButtonComponent text={"Add To Chrome"} />
          </div>
        </div>
      </nav>
    </div>
  );
}

export default NavBarComponent;
