import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faTwitter,
  faLinkedin,
  faFacebook,
  faGithub,
  faChrome,
} from "@fortawesome/free-brands-svg-icons";

import ButtonComponent from "../components/ButtonComponent";
import NavBarComponent from "../components/NavBarComponent";
import ExpandComponent from "../components/ExpandComponent";

function LandingPage() {
  return (
    <div>
      <NavBarComponent></NavBarComponent>
      <div
        className="section"
        style={{
          width: "100vw",
          height: "100vh",
          display: "flex",
          flexDirection: "row",
        }}
      >
        <div
          style={{
            width: "50%",
            paddingLeft: "5%",
            paddingRight: "5%",
            paddingTop: "10%",
            paddingBottom: "10%",
          }}
        >
          <div
            style={{
              paddingBottom: "10px",
              fontSize: "40px",
              fontStyle: "normal",
              fontWeight: "400",
            }}
          >
            BSCUT. The Ultimate AI Email Productivity Tool
          </div>
          <div
            style={{
              paddingBottom: "30px",
              fontSize: "20px",
              fontStyle: "normal",
              fontWeight: "400",
            }}
          >
            Leveraging the power of GPT 3.5 to help you communicate faster,
            easier, and smarter. Experience email without the BS.
          </div>
          <ButtonComponent
            text="Add to Chrome"
            icon={<FontAwesomeIcon icon={faChrome} color={"#F5F5FF"} />}
          />
        </div>
        <div
          style={{
            width: "50%",
            paddingLeft: "5%",
            paddingRight: "5%",
            paddingTop: "10%",
            paddingBottom: "10%",
          }}
        ></div>
      </div>
      <div
        className="section"
        style={{
          width: "100vw",
          height: "fit-content",
          paddingTop: "50px",
          paddingBottom: "50px",
          display: "flex",
          flexDirection: "column",
          backgroundColor: "#693808",
          alignItems: "center",
          textAlign: "center",
        }}
      >
        <div
          style={{
            color: "#FFFBEB",
            fontSize: "36px",
            fontStyle: "normal",
            fontWeight: "400",
          }}
        >
          What BSCUT can do
        </div>
        <div
          style={{
            color: "#FFFBEB",
            fontSize: "20px",
            fontStyle: "normal",
            fontWeight: "500",
          }}
        >
          Create an optimal email in less than 5 minutes.
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-around",
            marginTop: "50px",
          }}
        >
          <div
            style={{
              backgroundColor: "#7B491A",
              display: "flex",
              flexDirection: "column",
              width: "30%",
              padding: "20px",
            }}
          >
            <div></div>
            <div
              style={{
                color: "#FEF3C7",
                fontSize: "20px",
                fontStyle: "normal",
                fontWeight: "600",
              }}
            >
              Grammar Correction
            </div>
            <div
              style={{
                color: "#E5E7EB",
                fontSize: "16px",
                fontStyle: "normal",
                fontWeight: "400",
              }}
            >
              Polish your writing with GPT-powered grammar correction, ensuring
              your emails are clear, concise and error-free
            </div>
          </div>
          <div
            style={{
              backgroundColor: "#7B491A",
              display: "flex",
              flexDirection: "column",
              width: "30%",
              padding: "20px",
            }}
          >
            <div></div>
            <div
              style={{
                color: "#FEF3C7",
                fontSize: "20px",
                fontStyle: "normal",
                fontWeight: "600",
              }}
            >
              Reply Assistance
            </div>
            <div
              style={{
                color: "#E5E7EB",
                fontSize: "16px",
                fontStyle: "normal",
                fontWeight: "400",
              }}
            >
              Breaks down the Email into few simple questions for you to answer.
              Then sit back, have a biscuit and let AI do the rest
            </div>
          </div>
          <div
            style={{
              backgroundColor: "#7B491A",
              display: "flex",
              flexDirection: "column",
              width: "30%",
              padding: "20px",
            }}
          >
            <div></div>
            <div
              style={{
                color: "#FEF3C7",
                fontSize: "20px",
                fontStyle: "normal",
                fontWeight: "600",
              }}
            >
              Expand
            </div>
            <div
              style={{
                color: "#E5E7EB",
                fontSize: "16px",
                fontStyle: "normal",
                fontWeight: "400",
              }}
            >
              Adds the perfect amount of fluff to your emails, leaving you with
              more time to focus on the important details of your message
            </div>
          </div>
        </div>
      </div>
      <div className="section">
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            gap: "15px",
            paddingBottom: "20px",
          }}
        >
          <div
            style={{
              color: "#101828",
              fontSize: "36px",
              fontStyle: "normal",
              fontWeight: "400",
            }}
          >
            How to use BSCUT
          </div>
          <ButtonComponent
            text="Add to Chrome"
            icon={<FontAwesomeIcon icon={faChrome} color={"#F5F5FF"} />}
          />
        </div>
      </div>
      <div className="section">
        <div>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              gap: "15px",
              paddingBottom: "50px",
            }}
          >
            <div
              style={{
                color: "#101828",
                fontSize: "36px",
                fontStyle: "normal",
                fontWeight: "400",
              }}
            >
              Frequently asked questions
            </div>
            <div
              style={{
                color: "#101828",
                fontSize: "20px",
                fontStyle: "normal",
                fontWeight: "400",
              }}
            >
              Everything you need to know about the product
            </div>
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              gap: "15px",
            }}
          >
            <ExpandComponent
              title={"Can I change my plan later?"}
              content={"Yes you can! Change your plan anytime."}
            />
            <ExpandComponent
              title={"Is BSCUT safe?"}
              content={"Yes you can! Change your plan anytime."}
            />
            <ExpandComponent
              title={"Can other info be added to an Email?"}
              content={"Yes you can! Change your plan anytime."}
            />
            <ExpandComponent
              title={"How does billing work?"}
              content={"Yes you can! Change your plan anytime."}
            />
            <ExpandComponent
              title={"How do I change my account email?"}
              content={"Yes you can! Change your plan anytime."}
            />
          </div>
        </div>
      </div>
      <div
        className="section"
        style={{
          backgroundColor: "#CD9964",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <div
          style={{
            color: "#F5F5FF",
            fontSize: "30px",
            fontStyle: "normal",
            fontWeight: "400",
            marginBottom: "30px",
          }}
        >
          Take Control of your Emails
        </div>
        <div style={{ marginBottom: "25px" }}>
          <ButtonComponent
            text="Add to Chrome"
            icon={<FontAwesomeIcon icon={faChrome} color={"#F5F5FF"} />}
          />
        </div>
        <div
          style={{
            borderBottom: "1px solid #F5F5FF",
            width: "100%",
            marginBottom: "60px",
          }}
        ></div>
        <div
          style={{
            borderBottom: "1px solid #F5F5FF",
            width: "100%",
            marginBottom: "30px",
          }}
        ></div>
        <div
          style={{
            marginBottom: "30px",
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            width: "100%",
          }}
        >
          <div style={{ color: "#F5F5FF" }}>
            © 2023.BSCUT. All rights reserved.
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              width: "100px",
            }}
          >
            <FontAwesomeIcon icon={faTwitter} color={"#F5F5FF"} />
            <FontAwesomeIcon icon={faLinkedin} color={"#F5F5FF"} />
            <FontAwesomeIcon icon={faFacebook} color={"#F5F5FF"} />
            <FontAwesomeIcon icon={faGithub} color={"#F5F5FF"} />
          </div>
        </div>
      </div>
    </div>
  );
}

export default LandingPage;
