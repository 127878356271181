import React, { useState, useEffect, useRef } from "react";
import "./style.css";

function ExpandComponent(props) {
  const [open, setOpen] = useState(false);
  const [contentHeight, setContentHeight] = useState(open ? undefined : 0);
  const ref = useRef(null);

  useEffect(() => {
    if (!contentHeight || !open || !ref.current) return undefined;
    const resizeObserver = new ResizeObserver((el) => {
      setContentHeight(el[0].contentRect.height);
    });
    resizeObserver.observe(ref.current);
    return () => {
      resizeObserver.disconnect();
    };
  }, [contentHeight, open]);

  useEffect(() => {
    if (open) {
      setContentHeight(ref.current?.getBoundingClientRect().height);
    } else {
      setContentHeight(0);
    }
  }, [open]);

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        textAlign: "left",
        width: "70%",
      }}
    >
      <div
        className={"expand-title"}
        style={{
          color: "#1F2937",
          fontSize: "18px",
          fontStyle: "normal",
          fontWeight: "500",
          cursor: "pointer",
        }}
        onClick={() => setOpen(!open)}
      >
        {props.title}
      </div>
      <div className={"expand-content"} style={{ height: contentHeight }}>
        <div ref={ref}>
          <div
            style={{
              fontSize: "20px",
              fontStyle: "normal",
              fontWeight: "400",
            }}
          >
            {open && props.content}
          </div>
        </div>
      </div>
    </div>
  );
}

export default ExpandComponent;
